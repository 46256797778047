import React, {useEffect, useState, useRef}   from "react";
import {Link}                         from "react-router-dom";
import BootstrapTable                 from "react-bootstrap-table-next";
import {useTranslation}               from "react-i18next";
import {useAuth}                      from "@/services/Auth";
import Paginate from "../Paginate";
import useMoment from "../../hooks/useMoment";

function useColumns(filter, isExport = false) {
    let auth = useAuth();
    const {t} = useTranslation();
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const {momentLocal} = useMoment()

    let columns = [
        {
            dataField: 'id',
            text     : t('order_item_id'),
        },
        {
            dataField: 'order.order_number',
            text     : t('order_id'),
            formatter: (cell, row) => row.order?.id && <Link to={`/orders/${row.order?.id}`}>{cell}</Link> || cell,
        },
        {
            dataField: 'order.booking.booking_number',
            text     : t('booking_id'),
        },
    ];

    if (!auth.user.is_supplier_admin && !auth.user.is_supplier_operator) {
        columns.push({
            name: 'customer_name',
            dataField: 'order.company.name',
            text     : t('client_name'),
        });
    }

    if (!auth.user.is_supplier_admin && !auth.user.is_site_manager && !auth.user.is_supplier_operator && !auth.user.is_site_operator) {
        columns.push({
            name: 'fleet',
            dataField: 'order.fleet',
            text     : t('fleet_name'),
            formatter: (cell, row) => {
                if (cell && cell.id) {
                    return <Link to={`/fleets/${cell.id}`}>{cell && cell.name}</Link>
                }
                return <span>{cell && cell.name}</span>
            },

            exportFormatter: (cell) => cell?.name,
        });
    }

    columns = columns.concat([
        {
            name: 'vehicle',
            dataField: 'order.vehicle',
            text     : t('vehicle_registration_number'),
            formatter: (cell, row) => {
                const registration_number = cell?.registration_number || row?.order?.booking?.vehicle_registration_number;

                if (cell && !auth.user.is_supplier_admin && !auth.user.is_site_manager && !auth.user.is_supplier_operator && !auth.user.is_site_operator) {
                    return <Link to={`/vehicles/${cell.id}`}>{registration_number}</Link>
                }
                return <span>{registration_number}</span>
            },
            exportFormatter: (cell, row) => cell?.registration_number || row?.order?.booking?.vehicle_registration_number,
        },
        {
            name: 'vehicle_type',
            dataField: 'order.vehicle.vehicle_type',
            text     : t('type'),
            formatter: cell => cell && t(`vehicle_types.${cell}`),
            exportFormatter: cell => cell && t(`vehicle_types.${cell}`),
        },
        isExport && {
            name: 'payment_type',
            dataField: 'order.payment_type',
            text     : t('account_payment_method'),
        },
        {
            name: 'account_number',
            dataField: 'order.account_number',
            text     : t('account_number'),
            exportFormatter: (cell, row) => row.order.payment_type === 'Card'?'':cell,
        },
        isExport && {
            name: 'card_number',
            dataField: 'order.card_number',
            text     : t('card_number'),
        },
        {
            name: 'site',
            dataField: 'order.site',
            text     : t('site'),
            formatter: (cell, row) => {
                if (!auth.user.is_client_admin && !auth.user.is_fleet_manager) {
                    return cell && <Link to={`/sites/${cell.id}`}>{cell.name}</Link>
                } else if (auth.user.is_client_operator) {
                    return cell && <span>{cell.name}</span>
                }

                return cell && <Link to={`/bookings/site-card/${cell.id}`}>{cell.name}</Link>
            },
            exportFormatter: (cell) => cell?.name,
        },
        isExport && {
            name: 'country_of_service',
            dataField: 'order.site.country.label',
            text     : `${t('of_preposition',{item1: t('country'),item2: t('service')})}`,
        },
        isExport && {
            name: 'scan_longitude',
            dataField: 'order.site.longitude',
            text     : `${t('of_preposition',{item1: t('longitude'),item2: t('scan')})}`,
        },
        isExport && {
            name: 'scan_latitude',
            dataField: 'order.site.latitude',
            text     : `${t('of_preposition',{item1: t('latitude'),item2: t('scan')})}`,
        },
        {
            dataField: 'name',
            text     : t('service'),
            formatter: (cell, row) => row.quantity > 1 && cell  + ' x ' + row.quantity || cell,

        },
        {
            dataField: 'order.status',
            text     : t('status'),
            formatter: (cell, row) => {
                if( row.order?.booking?.status === 'completed' ){
                    return t(`order_status.${row.order?.booking?.status}`)
                }
                return  t(`order_status.${cell}`)
            },
            exportFormatter: (cell, row) => {
                if( row.order?.booking?.status === 'completed' ){
                    return t(`order_status.${row.order?.booking?.status}`)
                }
                return  t(`order_status.${cell}`)
            },
        },
        isExport && !auth.user.is_operator && {
          dataField: 'total_amount',
          text     : t('unit_price_gbp'),
          align    : 'right',
          exportFormatter: (total_amount, order) =>  (total_amount / order.quantity).toFixed(2),
        },
        {
          dataField: 'order.booking.type_str',
          text     : t('booking_type'),
            formatter: (cell, row) =>  row.is_subscription ? t(`booking_types.subscription`) : cell ?  t(`booking_types.${cell}`) : null,
        },

        isExport && !auth.user.is_operator && {
            dataField: 'net_amount',
            text     : t('net_amount'),
            align    : 'right',
        },


        isExport && !auth.user.is_operator && {
            dataField: 'vat_rate',
            text     : t('vat_rate'),
            align    : 'right',
        },

        isExport && !auth.user.is_operator && {
            dataField: 'vat',
            text     : t('vat'),
            align    : 'right',
        },
        !auth.user.is_operator && {
            dataField: 'billing_total',
            text     : t('amount'),
            align    : 'right',
            formatter: (billing_total, row) => <span>{billing_total && billing_total.toLocaleString(undefined, {minimumFractionDigits: 2})} {row.order?.billing_currency}</span>
        },

        {
            dataField: 'order.created_at',
            text     : t('booking.datetime'),
            formatter: datetime => datetime && momentLocal(datetime).tz(timezone).format(t('formats.short_datetime'))
        },
    ]).filter(Boolean);

    return filter && columns.filter(filter) || columns;
}


function OrderItems({ data: recordsProp, paginate, url, query, ...props }) {
    let auth = useAuth();
    const {t} = useTranslation();

    const perPage = props.perPage || 20;
    const exclude = props.exclude || [];
    const showPagination = paginate == null ? true : paginate;

    const request = useRef(null);
    const [page, setPage] = useState(0);
    const [totalRecords, setTotal] = useState(false);
    const [recordsDownloaded, setRecords] = useState(false);

    const getRecords = () => {
        request?.current?.abort && request.current.abort();

        const critera = { ... (query || {})};

        critera.take = perPage;
        critera.skip = page * perPage;

        request.current = auth.getRequest(url || '/order_items', critera);

        setRecords(false);

        request.current
            .then(response => {
                setRecords(response.data.order_items);
                setTotal(response.data.total);
            })
    }

    const columns = useColumns(_ => !exclude.includes(_.dataField) && !exclude.includes(_.name));

    const changePage =(event) => {
        setPage(event.selected)
    }

    const records = recordsProp || recordsDownloaded;

    useEffect(() => {
        if(!recordsProp)
        {
            getRecords();
        }

        return () => request?.current?.abort && request.current.abort();
    }, [page, query, url]);

    useEffect(() => {
        props.onData && props.onData(records);
    }, [records]);

    useEffect(() => {
        props.onTotal && props.onTotal(totalRecords);
    }, [totalRecords]);

    return (
        <>
            <BootstrapTable
                keyField="id"
                striped
                responsive
                hover
                columns={columns || []}
                data={records || []}
                noDataIndication={records instanceof Array ? t('no_data') : t('loading')}
                {...props}
            />
            {
                showPagination && records && totalRecords &&
                <Paginate {...{ changePage, totalRecords, page, perPage }} />
                || ''
            }
        </>
    );
}

export default OrderItems;

export {
    useColumns
};
