import {useTranslation}                                from "react-i18next";
import {useAuth}                                       from "@/services/Auth";
import {Link, useParams}                               from "react-router-dom";
import React, {useEffect, useState, useRef}            from "react";
import LoadingPage                                     from "@/components/LoadingPage";
import {Button, Card, Col, Row, Table, Form}           from "react-bootstrap";
import * as yup                                        from 'yup';
import AsyncSelect                                     from "react-select/async";
import ParkingPriceFields                              from "./components/ParkingPriceFields";
import LoadingButton                                   from "@/components/LoadingButton";
import SuccessDialog                                   from "../../../components/Modals/SuccessDialog";
import ConfirmDialog                                   from "../../../components/Modals/ConformDialog";
import { Helmet }                                      from "react-helmet";
import { useForm, useFieldArray }                      from "react-hook-form";
import { yupResolver }                                 from "@hookform/resolvers/yup";

function ClientPricing(props) {
    const {t, i18n} = useTranslation();
    let auth = useAuth();

    const getRequest = useRef({});
    const { current: requests } = getRequest;

    let { client: client_id, site: site_id } = useParams();
    const postRequest = auth.postRequest;
    const deleteRequest = auth.deleteRequest;


    const [loading, setLoading] = useState(true);
    const [gettingClientPricing, setGettingClientPricing] = useState(false);
    const [updatingClientPricing, setUpdatingClientPricing] = useState(false);
    const [showForm, setShowForm] = useState(true);

    const [site, setSite] = useState(props?.location?.state?.site || null);
    const [client, setClient] = useState(props?.location?.state?.client || null);
    const [clientPricing, setClientPricing] = useState('');
    const [updateSuccess, setUpdateSuccess] = useState(false);
    const [resetSuccess, setResetSuccess] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [deletingClientPricing, setDeletingClientPricing] = useState(false);
    const [existingPricingFound, setExistingPricingFound] = useState(false);

    const formatRecord = (company) => {
        const  object = {...company};

        object.value = company.id;
        object.label = company.name;

        return object;
    };

    const getSites = (search) => {
        requests.sites && requests?.sites?.abort && requests.sites.abort();

        return new Promise((resolve, reject) => {
            requests.sites = auth.getRequest('sites', { search, take: 15 });

            requests.sites.then(response => {
                    resolve(response.data.sites.map(formatRecord));
                })
                .catch(error => reject(error))
        });
    };

    const getClients = (search) => {
        requests.clients && requests?.clients?.abort && requests.clients.abort();

        return new Promise((resolve, reject) => {
            requests.clients = auth.getRequest('clients', { search, take: 15 });

            requests.clients.then(response => {
                    resolve(response.data.clients.map(formatRecord));
                })
                .catch(error => reject(error))
        });
    };

    useEffect(() => {
        return () => {
            for(var key in requests)
            {
                requests[key] && requests[key].abort && requests[key].abort();
            }
        }
    }, []);

    const getClientPrices = (client, site) => {
        if (site && client) {

            setGettingClientPricing(true);

            auth.getRequest(`/client-pricing/${site}/${client}`)
                .then(response => {
                    if (response.data.message === 'OK') {
                        setShowForm(false);
                    }

                    setGettingClientPricing(false)
                    setShowForm(true);

                    if(response.data.client_pricing)
                    {
                        setExistingPricingFound(true);
                        setClientPricing(response.data.client_pricing)
                        setSite(formatRecord(response.data.client_pricing.site));
                        setClient(formatRecord(response.data.client_pricing.client));
                    }
                })
                .catch(error => {})
                .finally(_ => {
                    setLoading(false);
                    setUpdatingClientPricing(false)
                })
        }

    };


    const handleSubmitLocal = (values) => {
        let updatedValues = values;

        if (client_id) {
            updatedValues.company_id = client_id;
        }

        if (site_id) {
            updatedValues.site_id = site_id;
        }

        setUpdatingClientPricing(true);
        postRequest('/client-pricing', updatedValues)
            .then(response => {
                if (response.data.message === 'OK') {
                    setUpdateSuccess(true);
                }
                setUpdatingClientPricing(false)
            })
            .catch(error => {
                setUpdatingClientPricing(false)
            })
    }

    const handleClose = () => {
        setUpdateSuccess(false);
        setShowConfirm(false);
    };

    const handleSuccess = () => {
        if (client_id) {
            props.history.push(`/clients/${client_id}`)
        } else if (site_id) {
            props.history.push(`/sites/${site_id}`)
        } else {
            props.history.push(`/`)
        }
    };

    const resetToDefaultPricing = (site_id, company_id) => {
        setShowConfirm(false);
        setLoading(true);

        if(!site_id || !company_id)
        {
            setResetSuccess(true);
        }
        else
        auth.deleteRequest(`/client-pricing/${site_id}/${company_id}`)
            .then(response => {
                if (response.data.message === 'OK') {
                    setResetSuccess(true);
                }
            })
            .catch(error => {})
            .finally(_ => {
                setLoading(false);
            })
    }


    useEffect(() => {
        if (client_id && site_id) {
            getClientPrices(client_id, site_id);
        }
        else
        {
            setLoading(false);
        }
    }, [])

    function ClientPricingForm({onSubmit, clientPricing, propSite, propClient})
    {
        const schema = yup.object().shape({
            site_id              : yup.string().required(t('form_validation.site_name_is_required')),
            company_id           : yup.string().required(t('form_validation.company_is_required')),
            commission_rate_type : yup.string().required(t('form_validation.commission_rate_type_is_required')),
            commission_rate_value: yup.number().required(t('form_validation.commission_value_is_required'))
                                    .transform(v => v === '' || isNaN(v) ? null : v).nullable(),
            pre_booking_parking_fee: yup.number().transform(v => v === '' || isNaN(v) ? null : v).nullable(),
            parking_prices       : yup.array().of(yup.object().shape({
                price          : yup.number().required(t('form_validation.parking_price_is_required')),
                period_value   : yup.number().required(t('form_validation.parking_price_period_value_is_required')),
                per_period_type: yup.string().required(t('form_validation.parking_price_per_period_type_is_required')),
                up_to_value    : yup.number().required(t('form_validation.parking_price_up_to_value_is_required')),
                up_to_period   : yup.string().required(t('form_validation.parking_price_up_to_period_is_required')),
            })).min(1, t('form_validation.parking_price_is_required')),
        });

        const {
            handleSubmit,
            control,
            register,
            formState: { errors },
            setError,
            watch,
            setValue,
            getValues
        } = useForm({
            defaultValues: (_ => {
                const values = {...clientPricing};

                values.selected_site = null;
                values.selected_company = null;

                if(!values.parking_prices)
                {
                    values.parking_prices= [];
                }

                if(!values.site_id)
                {
                    values.site_id = propSite?.id || site_id;
                    values.selected_site = propSite && formatRecord(propSite);
                }
                if(!values.company_id)
                {
                    values.company_id = propClient?.id || client_id;
                    values.selected_company = propClient && formatRecord(propClient);
                }

                return values;
            })(),
            resolver: yupResolver(schema),
        });

        useEffect(() => {
            register('site_id');
            register('selected_site');
            register('company_id');
            register('selected_company');
            register('parking_prices');
        }, []);

        const site           = watch('selected_site');
        const company        = watch('selected_company');

        const { fields: parking_prices, remove: removePrice, append: appendPrice } = useFieldArray({
            control,
            name: 'parking_prices',
        });

        return (
            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                <ConfirmDialog
                    message='reset_parking_pricing_for_this_site_client'
                    cancel='conform_dialog.cancel'
                    accept='conform_dialog.reset'
                    acceptAction={() => resetToDefaultPricing(site?.value || site_id, company?.value || client_id)}
                    show={showConfirm}
                    handleClose={handleClose}
                />
                <Row>
                    {
                        !site_id &&
                        <Col sm={6} md={3} className="col-12">
                            <Form.Label className="my-2">
                                {t('site')}
                            </Form.Label>
                            <AsyncSelect
                                className={(!!errors.site_id ? 'is-invalid' : '')}
                                isClearable={true}
                                value={site}
                                onChange={(e) => {
                                    setValue('site_id',  e && e.value);
                                    setValue('selected_site', e);
                                    setSite(e);
                                    getClientPrices(company && company.value, e && e.value);
                                }}
                                placeholder={t('search_placeholder')}
                                defaultOptions={true}
                                loadOptions={getSites}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.site_id && errors.site_id.message}
                            </Form.Control.Feedback>
                        </Col>
                    }
                    {
                        !client_id &&
                        <Col sm={6} md={3} className="col-12">
                            <Form.Label className="my-2">
                                {t('client')}
                            </Form.Label>
                            <AsyncSelect
                                className={(!!errors.company_id ? 'is-invalid' : '')}
                                isClearable={true}
                                value={company}
                                onChange={(e) => {
                                    setValue('company_id',  e && e.value);
                                    setValue('selected_company', e);
                                    setClient(e);
                                    getClientPrices(e ? e.value : null, site ? site.value : null);
                                }}
                                placeholder={t('search_placeholder')}
                                defaultOptions={true}
                                loadOptions={getClients}
                            />
                            <Form.Control.Feedback type="invalid">
                                {errors.company_id && errors.company_id.message}
                            </Form.Control.Feedback>
                        </Col>
                    }
                    {
                        !client_id &&
                        <Col sm={6} md={3} className="col-12">
                            <Form.Label className="my-2">
                                {t('billing_currency')}
                            </Form.Label>

                            <input
                                readOnly
                                type="text"
                                value={company?.currency}
                                className="form-control"
                            />

                            <Form.Control.Feedback type="invalid">
                                {errors.company_id && errors.company_id.message}
                            </Form.Control.Feedback>
                        </Col>
                    }
                    <Col sm={6} md={3} className="col-12">
                        <Form.Label className="my-2">
                            {t('commission_rate_type')}
                        </Form.Label>

                        <Form.Select
                            {...register('commission_rate_type')}
                            placeholder={t('commission_rate_type')}
                            isInvalid={!!errors.commission_rate_type}
                        >
                            <option value="">{t('choose')}</option>
                            {
                                ['percentage', 'flat_rate'].map(commissionRateTypeOption =>
                                    <option value={commissionRateTypeOption} key={commissionRateTypeOption}>
                                        {t(`commission_rate_options.${commissionRateTypeOption}`)}
                                    </option>
                                )
                            }
                        </Form.Select>

                        <Form.Control.Feedback type="invalid">
                            {errors.commission_rate_type && errors.commission_rate_type.message}
                        </Form.Control.Feedback>
                    </Col>

                    <Col sm={6} md={3} className="col-12">
                        <Form.Label className="my-2">
                            {t('commission_rate_value')}
                        </Form.Label>

                        <Form.Control
                            type="number"
                            placeholder={t('commission_rate_value')}
                            {...register('commission_rate_value')}
                            isInvalid={!!errors.commission_rate_value}
                        />

                        <Form.Control.Feedback type="invalid">
                            {errors.commission_rate_value && errors.commission_rate_value.message}
                        </Form.Control.Feedback>
                    </Col>

                    <Col sm={6} md={3} className="col-12">
                        <Form.Label className="my-2">
                            {t('pre_booking_parking_fee')}
                        </Form.Label>

                        <Form.Control
                            type="number"
                            placeholder={t('pre_booking_parking_fee')}
                            {...register('pre_booking_parking_fee')}
                            isInvalid={!!errors.pre_booking_parking_fee}
                        />

                        <Form.Control.Feedback type="invalid">
                            {errors.pre_booking_parking_fee && errors.pre_booking_parking_fee.message}
                        </Form.Control.Feedback>
                    </Col>
                </Row>

                <Row className="mt-4 px-2">
                    <Table striped responsive bordered hover size="sm" className="mt-4">
                        <thead>
                        <tr>
                            <th style={{minWidth: '50px'}}>{t('price')}</th>
                            <th style={{minWidth: '120px'}}>{t('period_value')}</th>
                            <th style={{minWidth: '120px'}}>{t('period_per')}</th>
                            <th style={{minWidth: '120px'}}>{t('upto_value')}</th>
                            <th style={{minWidth: '120px'}}>{t('upto_period')}</th>
                            <th>&nbsp;</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            parking_prices?.map((parking_price, index) => (
                                <ParkingPriceFields
                                    key={parking_price.id}
                                    errors={errors && errors.parking_prices && errors.parking_prices[index] || {}}
                                    register={register}
                                    watch={watch}
                                    index={index}
                                    remove={_ => {
                                        removePrice(index);
                                    }}
                                />
                            ))
                        }
                        <tr>
                            <td>
                                <div className="d-grid gap-2">
                                    <Button variant="success" className="has-icon" onClick={() => {
                                        appendPrice({
                                            price          : '',
                                            period_value   : '',
                                            per_period_type: '',
                                            up_to_value    : '',
                                            up_to_period   : '',
                                        })
                                    }}>
                                        <div className="d-flex align-items-center justify-content-center">
                                            <span>{t('add_parking_prices')}</span>
                                            <i className="bi bi-plus"/>
                                        </div>
                                    </Button>
                                </div>
                                {errors.parking_prices && <p className="text-danger font-weight-bold" >{errors.parking_prices.message}</p>}
                            </td>
                            <td colSpan={5}>&nbsp;</td>
                        </tr>
                        </tbody>
                    </Table>
                </Row>

                <Row>
                    <Col sm={existingPricingFound && 6} className="col-12">
                        <LoadingButton loading={updatingClientPricing} type="submit" titleTranslationKey="save_client_pricing"/>
                    </Col>
                    {
                        existingPricingFound &&
                        <Col sm={6} className="col-12">
                            <div className="d-grid gap-2">
                                <LoadingButton onSubmit={() => setShowConfirm(true)} loading={deletingClientPricing} variant='danger' titleTranslationKey="reset_to_default_pricing"/>
                            </div>
                        </Col>
                    }
                </Row>
            </Form>
        )
    }

    return (
        loading ? (
            <LoadingPage/>
        ) : (
            <Card className="mx-2 my-2 p-2">
                <Helmet>
                    <title>{t('client_pricing')} - {t('app')}</title>
                </Helmet>

                <SuccessDialog
                    show={updateSuccess}
                    message="client_pricing_saved"
                    handleClose={handleSuccess}
                />


                <SuccessDialog
                    show={resetSuccess}
                    message="client_pricing_reset"
                    handleClose={handleSuccess}
                />

                <Card.Subtitle>
                    <h4 className="text-primary">{t('client_pricing')}</h4>

                    <Row>
                    {client_id && client?.name && (
                        <Col xs={12} sm={6} lg={4} xl={3}>
                            <strong>{t('client')}:</strong>

                            <Link to={`/clients/${client_id}`} className="ms-2">
                                {client.name}
                            </Link>
                        </Col>
                    )}
                    {site_id && site?.name && (
                        <Col xs={12} sm={6} lg={4} xl={3}>
                            <strong>{t('site')}:</strong>

                            <Link to={`/sites/${site_id}`} className="ms-2">
                                {site.name}
                            </Link>
                        </Col>
                    )}
                    </Row>
                </Card.Subtitle>

                <div className="site-find-card position-relative">
                    {
                        gettingClientPricing &&
                        <LoadingPage/>
                    }
                    {
                        showForm &&
                        <ClientPricingForm
                            onSubmit={handleSubmitLocal}
                            clientPricing={clientPricing}
                            propSite={site}
                            propClient={client}
                        />
                    }
                </div>
            </Card>
        )
    )
}

export default ClientPricing;
