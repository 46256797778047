import React, { useEffect, useState }   from "react";
import * as yup from 'yup';
import { GPSIcon } from "@/components/icons";
import { useTranslation }               from "react-i18next";
import { Countries }   from "@/services";
import { Form, Button } from "react-bootstrap";
import LoadingPage from "@/components/LoadingPage";
import {useGeoServices} from "../../services/GeoServices";

function useLocationFieldsSchema()
{
  const {t} = useTranslation();

  return yup.object().shape({
    longitude: yup.number()
      .nullable(),

    latitude: yup.number()
      .nullable(),

    address_1: yup.string()
      .required(t('form_validation.is_required', { attribute: t('address_1') }))
      .max(255, t('form_validation.max_length', { attribute: t('address_1'), max: 255 })),
    address_2: yup.string()
      .nullable()
      .max(255, t('form_validation.max_length', { attribute: t('address_2'), max: 255 })),
    address_3: yup.string()
      .nullable()
      .max(255, t('form_validation.max_length', { attribute: t('address_2'), max: 255 })),
    city: yup.string()
      .required(t('form_validation.is_required', { attribute: t('city') }))
      .max(255, t('form_validation.max_length', { attribute: t('city'), max: 255 })),
    county: yup.string()
      .required(t('form_validation.is_required', { attribute: t('county') }))
      .max(255, t('form_validation.max_length', { attribute: t('county'), max: 255 })),

    country_id: yup.number()
      .typeError(t('form_validation.is_required', { attribute: t('country') }))
      .required(t('form_validation.is_required', { attribute: t('country') })),

    postcode: yup.string()
      .required(t('form_validation.is_required', { attribute: t('post_code') }))
      .max(255, t('form_validation.max_length', { attribute: t('post_code'), max: 255 })),
  });
}

function LocationFields({ register, watch, errors, setValue, useCurrentLocation, previewAddressOnly, ...props }) {
  const path = props.path || '';

  const { mapIsLoaded } = useGeoServices();

  const {t} = useTranslation();
  const [currentLocation, setCurrentLocation] = useState(null);

  useEffect(_ => {
    register(`${path}latitude`);
    register(`${path}longitude`);
  }, []);

  const [countries, setCountries] = useState(null);

  useEffect(_ => {
    if(!countries)
    {
      Countries.get().then(setCountries);
    }
  }, [countries]);

  useEffect(_ => {
    if(previewAddressOnly)
    {
      register(`${path}country_id`);
      register(`${path}address_1`);
      register(`${path}address_2`);
      register(`${path}address_3`);
      register(`${path}county`);
      register(`${path}city`);
      register(`${path}postcode`);
    }
  }, [previewAddressOnly]);

  const getLocation = () => {
    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(showPosition, locationError);
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  const showPosition = (position) => {
    const geocoder = new window.google.maps.Geocoder();

    const location = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
    };

    setValue(`${path}latitude`, location.lat);
    setValue(`${path}longitude`, location.lng);

    geocoder
    .geocode({ location })
    .then((response) => {
      if (response.results[0]) {
        const result = response.results[0];

        var full_address = {
          address_1: '',
          county: '',
          city: '',
          postcode: '',
          country: '',
        };

        var address_1 = "";

        for(var key in result.address_components)
        {
          const component = result.address_components[key];

          if(component.types.includes('streat_number'))
          {
            address_1 = component.long_name + " " + address_1;
          }

          if(component.types.includes('route'))
          {
            address_1 += component.long_name;
          }

          if(component.types.includes('administrative_area_level_2'))
          {
            setValue(`${path}county`, component.long_name);
          }

          if(component.types.includes('postal_town'))
          {
            setValue(`${path}city`, component.long_name);
          }

          if(component.types.includes('postal_code'))
          {
            setValue(`${path}postcode`, component.long_name);
          }

          if(component.types.includes('country'))
          {
            const country = countries.find(_ => _.name == component.long_name);

            if(country)
            {
              setValue(`${path}country_id`, country.id);
            }
          }
        }

        address_1 && setValue(`${path}address_1`, address_1);
      }
    });
  };

  const errorsGet = (path) => {

    const peices = path.split('.');

    var focus = errors;

    for(var i in peices)
    {
      if(!(focus instanceof Object))
      {
        return null;
      }

      focus = focus[ peices[i] ];
    }

    return focus;
  }

  const locationError = () => {
    setCurrentLocation(null);
  }

  const country_id = watch && watch(`${path}country_id`);
  const address_1  = watch && watch(`${path}address_1`);
  const address_2  = watch && watch(`${path}address_2`);
  const address_3  = watch && watch(`${path}address_3`);
  const county     = watch && watch(`${path}county`);
  const city       = watch && watch(`${path}city`);
  const postcode   = watch && watch(`${path}postcode`);
  const country = countries && countries.find(_ => _.id == country_id);

  const displayAddress = {
    address_1,
    address_2,
    address_3,
    county,
    city,
    postcode,
    country: country?.name,
  };

  if(previewAddressOnly && !watch)
  {
    throw "please pass watch function to element";
  }

  if(!countries)
  {
    return <LoadingPage />;
  }


  return (
    <>
      <h3 className="h6 text-primary">{t('location')}</h3>

      {useCurrentLocation && (
        <Button className="w-100" variant="success" size="sm" onClick={getLocation}>
          {t('use_current_location')}

          <GPSIcon />
        </Button>
      ) || ''}


      {previewAddressOnly && (
        <>
        <p className={
          (errorsGet(`${path}latitude.message`) ||
            errorsGet(`${path}longitude.message`) ||
            errorsGet(`${path}country_id.message`) ||
          errorsGet(`${path}address_1.message`) ||
          errorsGet(`${path}address_2.message`) ||
          errorsGet(`${path}address_3.message`) ||
          errorsGet(`${path}county.message`) ||
          errorsGet(`${path}city.message`) ||
          errorsGet(`${path}postcode.message`) ) && 'is-invalid' || ''
        }>
          {displayAddress && Object.values(displayAddress).filter(_ => !!_).map((line) => (
            <div>{line}</div>
          )) || ''}
        </p>

        <Form.Control.Feedback type="invalid">
          {t('form_validation.is_required', { attribute: t('address') })}
        </Form.Control.Feedback>

        </>
      ) || (

        <>
      <Form.Group className="mb-3">
        <label htmlFor="country_id" className="my-2">{t('country')}</label>

        <Form.Select
          {...register(`${path}country_id`)}
          isInvalid={!!errorsGet(`${path}country_id`)}
          >
          <option value="">{t('country')}</option>

          {countries.map(country => (
            <option key={country.id} value={country.id}>{country.name}</option>
          ))}
        </Form.Select>

        <Form.Control.Feedback type="invalid">
          {errorsGet(`${path}country_id.message`)}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <label htmlFor="address_1" className="my-2">{t('address')}</label>
        <Form.Control
          {...register(`${path}address_1`)}
          type="text"
          isInvalid={!!errorsGet(`${path}address_1`)}
        />

        <Form.Control.Feedback type="invalid">
          {errorsGet(`${path}address_1.message`)}
        </Form.Control.Feedback>

        <Form.Control
          {...register(`${path}address_2`)}
          type="text"
          isInvalid={!!errorsGet(`${path}address_2`)}
        />

        <Form.Control.Feedback type="invalid">
          {errorsGet(`${path}address_2.message`)}
        </Form.Control.Feedback>

        <Form.Control
          {...register(`${path}address_3`)}
          type="text"
          isInvalid={!!errorsGet(`${path}address_3`)}
        />

        <Form.Control.Feedback type="invalid">
          {errorsGet(`${path}address_3.message`)}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <label htmlFor="county" className="my-2">{t('county')}</label>
        <Form.Control
          {...register(`${path}county`)}
          type="text"
          placeholder={t('county')}
          isInvalid={!!errorsGet(`${path}county`)}
        />
        <Form.Control.Feedback type="invalid">
          {errorsGet(`${path}county.message`)}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <label htmlFor="city" className="my-2">{t('town_city')}</label>
        <Form.Control
          {...register(`${path}city`)}
          type="text"
          placeholder={t('town_city')}
          isInvalid={!!errorsGet(`${path}city`)}
        />
        <Form.Control.Feedback type="invalid">
          {errorsGet(`${path}city.message`)}
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group className="mb-3">
        <label htmlFor="post_code" className="my-2">{t('post_code')}</label>
        <Form.Control
          {...register(`${path}postcode`)}
          type="text"
          placeholder={t('post_code')}
          isInvalid={!!errorsGet(`${path}postcode`)}
        />
        <Form.Control.Feedback type="invalid">
          {errorsGet(`${path}postcode.message`)}
        </Form.Control.Feedback>
      </Form.Group>

        </>
      )}

    </>
  );
}

export default LocationFields;

export { useLocationFieldsSchema };
