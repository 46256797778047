// MapProvider.jsx
import React, {createContext, useContext} from 'react';
import { useJsApiLoader } from '@react-google-maps/api';

export const GeoServicesContext = createContext({ isLoaded: false });

export function ProvideGeoServices({ children, mapAPIKey }) {
  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: mapAPIKey,
    libraries: ['places'],
  });

  return (
    <GeoServicesContext.Provider value={{ isLoaded }}>
      {children}
    </GeoServicesContext.Provider>
  );
}

export const useGeoServices = () => {
  return useContext(GeoServicesContext);
}
