import React, {useEffect, useCallback, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Form, ListGroup} from "react-bootstrap";

import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
} from 'use-places-autocomplete'
import {useGeoServices} from "../../services/GeoServices";

export default function PlacesAutocomplete({setSelected, setAddress, address, listView, action}) {

  const {isLoaded} = useGeoServices();

  const {t, i18n} = useTranslation();

    if (!isLoaded) return <p>{t('loading')}</p>

    return <Autocomplete
        setSelected={setSelected}
        setAddress={setAddress}
        listView={listView}
        address={address}
        action={action}
    />
}


const Autocomplete = ({setSelected, setAddress, address, listView, action}) => {
    const {t, i18n} = useTranslation();
    const [manualClear, setManualClear ]= useState(false);
    const {
        ready,
        value,
        setValue,
        suggestions: {status, data},
        clearSuggestions,
    } = usePlacesAutocomplete();

    const handleSelect = async (address) => {
        setValue(address, false)
        setAddress && setAddress(address, manualClear)
        clearSuggestions()

        const results = await getGeocode({address})

        const {lat, lng} = await getLatLng(results[0])
        setSelected && setSelected({lat, lng})
    }

    useEffect(() => {
      if(address)
        handleSelect(address);
    }, [address])

    useEffect(() => {
        if (!value) {
            setAddress &&setAddress(null, manualClear)
            setSelected && setSelected(null)
        }

    }, [value])

    return (
        <div className="address-combo">
            <div className={`${listView ? 'input-group my-3' : 'mb-3'}`}>
                <Form.Control
                    value={value ?? ''}
                    onChange={(e) => {
                        setValue(e.target.value)
                        setManualClear(!e.target.value);
                    }}
                    placeholder={t('location')}
                    className={`${listView ? 'shadow-none' : ''}`}
                    type="text"
                    aria-describedby="button-search"
                />
                {listView &&
                    <Button type={`${action ? 'button' : 'submit' }`} onClick={action} id="button-search">
                        <i className="bi bi-search"></i>
                    </Button>}

            </div>
            <ListGroup>
                {
                    data.map(({place_id, description}) => (
                        <ListGroup.Item action onClick={() => handleSelect(description)}
                                        key={place_id}>{description}</ListGroup.Item>
                    ))
                }
            </ListGroup>
        </div>
    )
}
